import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import { fetchConToken } from '../../../helpers/fetch';
import { format } from 'date-fns';
import { LoadingSpinnerBlue } from '../../ui/LoadingSpinnerBlue';
import { AddNotesModal } from './AddNotesModal';
import { useDispatch } from 'react-redux';
import { setHotLeadId, setNotes, uiOpenModalAddNotes } from '../../../actions/ui';


export const HotLeadsItems = ({ leads, isLoading, setLeads }) => {
  const dispatch = useDispatch();
  if (isLoading) {
    return <LoadingSpinnerBlue disableCenter={true} />
  }
  const updateType = async (id, type_response, id_calltools) => {
    const update = async () => {
      const body = await fetchConToken(`calltools/updatehotlead`, { id, type_response, id_calltools }, 'PUT');
      if (body.ok) {
        Swal.fire('Success', "Type changed", "success");
        setLeads(body.leads);
      } else {
        Swal.fire("Error", body.msg, "error");
      }
    }
    update();
  };
  const seeNotes = async (id, notes) => {

    dispatch(setHotLeadId(id));
    const getNotes = async () => {
      const body = await fetchConToken(`calltools/hotleads/notes/${id}`);
      dispatch(setNotes(body.data));
      dispatch(uiOpenModalAddNotes());
    }
    getNotes();
  };
  return (
    <div className='container-fluid table-responsive mb-2'>
      <>
        {leads && <table className={isMobile ? "d-block overflow-scroll table table-sm table-bordered bg-light table-striped rounded rounded-3 overflow-hidden" : "table table-striped bg-light table-hover overflow-hidden"}>
          <thead className='bg-dark text-light align-middle'>
            <tr>
              <th className="text-center" scope="col">Date</th>
              <th className="text-center" scope="col">Name</th>
              <th className="text-center" scope="col">Phone number</th>
              <th className="text-center" scope="col">Address</th>
              {/* <th className="text-center" scope="col">Utility provider</th> */}
              {/* <th className="text-center" scope="col">Utility bill</th> */}
              {/* <th className="text-center" scope="col">Credit score</th> */}
              {/* <th className="text-center" scope="col">Call hour</th> */}
              <th className="text-center" scope="col">Type</th>
              <th className="text-center" scope="col">Source</th>
              <th className="text-center" scope="col">Type response</th>
              <th className="text-center" scope="col">Notes</th>
              <th className="text-center" scope="col" colSpan={7}>Set type</th>
            </tr>
          </thead>

          {leads.length > 0 && leads.map(lead => {
            return (
              <tbody key={lead.id}>
                <tr>
                  <td className="text-center text-dark text-nowrap">{lead.date ? format(new Date(lead.date), 'dd-MM-yyyy') : '-'}</td>
                  <td className="text-center text-dark text-uppercase">{lead.name}</td>
                  <td className="text-center text-dark">{lead.phone_number}</td>
                  <td className="text-center text-dark">{lead.address}</td>
                  {/* <td className="text-center text-dark">{lead.utility_provider}</td> */}
                  {/* <td className="text-center text-dark">{lead.utility_bill}</td> */}
                  {/* <td className="text-center text-dark">{lead.credit_score}</td> */}
                  {/* <td className="text-center text-dark">{lead.hour}</td> */}
                  <td className="text-center text-dark">{lead.type}</td>
                  <td className="text-center text-dark">{lead.source}</td>
                  <td className="text-center text-dark">{lead.type_response}</td>
                  <td className="text-center text-dark"><button className={lead.notes !== '-' && lead.notes !== '' && lead.notes !== ' ' ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => seeNotes(lead.id, lead.notes)}><i className='fa fa-note-sticky'></i></button></td>
                  <td className="text-center text-dark"><button className='btn btn-success' onClick={() => updateType(lead.id, "App", lead.id_calltools)}>App</button></td>
                  <td className="text-center text-dark"><button className='btn btn-warning' onClick={() => updateType(lead.id, "Ans", lead.id_calltools)}>Ans</button></td>
                  <td className="text-center text-dark"><button className='btn btn-danger' onClick={() => updateType(lead.id, "No Ans", lead.id_calltools)}>No Ans</button></td>
                  <td className="text-center text-dark"><button className='btn btn-dark' onClick={() => updateType(lead.id, "Rent", lead.id_calltools)}>Rent</button></td>
                  <td className="text-center text-dark"><button className='btn btn-light' onClick={() => updateType(lead.id, "LC", lead.id_calltools)}>LC</button></td>
                  <td className="text-center text-dark"><button className='btn btn-dark' onClick={() => updateType(lead.id, "Disq", lead.id_calltools)}>Disq</button></td>
                  <td className="text-center text-dark"><button className='btn btn-light' onClick={() => updateType(lead.id, "Msj", lead.id_calltools)}>Msj</button></td>
                </tr>
              </tbody>
            );
          })}
        </table>
        }
      </>
      <AddNotesModal />
    </div>);
};
