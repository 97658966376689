import { useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from '../ui/LoadingSpinner';
import Swal from 'sweetalert2';
import { fetchConToken } from '../../helpers/fetch';

export const AdminScreen = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handlePoints = async (e) => {
    const body = await fetchConToken(`userdata/points`);
    if (body.ok) {
      Swal.fire(t('success.title'), 'Points', "success");
    } else {
      Swal.fire("Error", body.msg, "error");
    }
  };
  const sendNotification = async (e) => {
    const res = await fetch('https://fcm.googleapis.com/v1/projects/be-cards/messages:send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ya29.c.c0AY_VpZgCO1D4W4SDzXTqwzKW4LlAzNRoq_44H8X5yZOn-P-MD5rYGhUlS9D6sALbfBo__iOSByr8r5788CR-YD63QfTBHdwxEke_saAOUDOp0tiIs_1KTaoAZT-ipobuXio_vQSmjwUJ9y2e87QjhNy_KHopmrjwESeqmWy2QlN6RDX3xL8UTRSoYmhMUEkqjmA1o2j9opWA_pdjde3rrOF1IC-tayXTRvXfyJ98oxDpTr7Jg3QuvYL9bsSrbqGqZamQbVrv0xzjARjy0dupntmx9IaQDOrbcnjw5phZ9S-An8NrOy9V7GCq44s7vLF-XXv1O4zcQ2aVUTsF42BsqY52v0tNG2XukyNgLgEW8VlLzMJSfpz1fBNNG385AbZ_8XR_ge4kFofb1y5Syavo2IabggXIMh_7iVQi08rgUlZf1yn1-eUUiSXQOVUU4ntlrO1MM_Wf26hJzUkjU6a0tk45yjoUqnkItk9w0roQJZYVrk7yR0_iXZ7wvVb_biRlZtSj47tOYuSR-lVv0o6I7UpydQYedFqv8Xc3ieXi1ZBtlzrkR4Fvqi92Zo_y-J3VnoOffVZWbogIh8gkx9-yoxS9plyZJBnM4jjS4wVd_9k86c07Sbr_dx03dfnFVInW4p1bQFI_dB-1c-ifZ0hYnOop_1WhaO9RZ6dld6OF0jgbcQ3M75F3qepJq_adwpcJYUs58s4phVr2Yuv6u9j5vS83f0u8_x2tvR46w-7Xm8960yweXUMO-uyl6VZdFUMvZMI7czZSesSY7yUWstrIUxV05n8I7u0uXuroZcwOZ11R2ZudnjIwurg4pBs4Y6d7boqF9r4mf6ffSnScBqxWOSOJQ8qpfnsip8Oda8uh6IqreWpSi-VsQrIhFmyVt8kQW7-WhFQ1J5Qsu81FkmsiV9MckVFgSf7bJYFnlYO0YRRlcXx6rs9MFX5iriImmeoo_-hh6zS7ib0pJcyxb0dlbb_p63Ui75xSOo2l47ksI7uebhvVrlarU0q',
      },
      body: JSON.stringify({
        message: {
          token: 'dcAvTL0YRumtkgyy6Ggl-x:APA91bFg3KJLriVELj-nFeJnx8lN9ksBHANtto1q_k27uBgaG_qAsjqiUYbTofI0HZ3ytIr2FRUaOKc_AhhcA8rna9SpJHbhuAT7a2Dugla_PtJ-dAJvZPR4SYbK_qfXwZhYc53RBjCf',
          notification: {
            title: 'Title of Notification',
            body: 'ASDSADASDAS',
          },
        }
      }),
    }).then((response) => {
      return response.json()
    });
    console.log(res)
  };
  const exportActiveUsers = async (e) => {
    const body = await fetchConToken(`auth/export/active/users`);
    if (body.ok) {
      Swal.fire(t('success.title'), 'Users exported', "success");
    } else {
      Swal.fire("Error", body.msg, "error");
    }
  };

  return (
    <>
      {
        loading
          ?
          <LoadingSpinner />
          :
          <div>
            {
              isMobile
                ?
                <div className='container d-flex flex-column justify-content-center align-items-center text-center' data-aos="fade-up" data-aos-duration="1000">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <Link to="/addzoom" className='m-2'>
                      <button className="btn btn-light bg-invi text-light" title="Add users" style={{ width: "250px" }}>
                        <i className="fas fa-video"></i> Notify Zoom Link
                      </button>
                    </Link>
                    {/* <div className='m-2'>
                      <button onClick={handlePoints} className="btn btn-light bg-invi text-light" title="Add users" style={{ width: "250px" }}>
                        Reassignate Points
                      </button>
                    </div> */}

                    <div className="dropdown-center">
                      <button className="btn btn-light dropdown-toggle m-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-cog"></i> Actions
                      </button>
                      <ul className="dropdown-menu bg-invi p-0 m-0 border-0" aria-labelledby="dropdownMenuButton1">
                        <div className='gap-1 d-flex flex-column justify-content-center align-items-center'>
                          <Link to="/hotleads">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-fire"></i> Hot leads
                            </button>
                          </Link>
                          <Link to="/userlist">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> Users list
                            </button>
                          </Link>
                          {/* <Link to="/userdeals">
                            <button className="btn btn-light bg-invi text-light" title="Deals tracker" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> Deals tracker
                            </button>
                          </Link> */}
                          <Link to="/addcard">
                            <button className="btn btn-light bg-invi text-light" title="Add card" style={{ width: "250px" }}>
                              <i className="fas fa-square-plus"></i> Create Card
                            </button>
                          </Link>
                          <Link to="/givecard">
                            <button className="btn btn-light bg-invi text-light" title="Give card" style={{ width: "250px" }}>
                              <i className="fas fa-gift"></i> Give Card
                            </button>
                          </Link>
                          <Link to="/addevent">
                            <button className="btn btn-light bg-invi text-light" title="Add event" style={{ width: "250px" }}>
                              <i className="fas fa-calendar-plus"></i> Create Event
                            </button>
                          </Link>
                          <Link to="/addreward">
                            <button className="btn btn-light bg-invi text-light" title="Add reward" style={{ width: "250px" }}>
                              <i className="fas fa-trophy"></i> Create Reward
                            </button>
                          </Link>
                          <Link to="/addpost">
                            <button className="btn btn-light bg-invi text-light" title="Add post" style={{ width: "250px" }}>
                              <i className="fas fa-bell"></i> Create Announcement
                            </button>
                          </Link>
                          <Link to="/assigncard">
                            <button className="btn btn-light bg-invi text-light" title="Assign card canva" style={{ width: "250px" }}>
                              <i className="fas fa-hand-point-up"></i> Assign Cards
                            </button>
                          </Link>
                          {/* <Link to="/addusersdata">
                            <button className="btn btn-light bg-invi text-light" title="Add users data canva" style={{ width: "250px" }}>
                              <i className="fas fa-door-open"></i> Upload Canvasser Data
                            </button>
                          </Link> */}
                          {/* <Link to="/exportcsv">
                            <button className="btn btn-light bg-invi text-light" title="Assign card closer" style={{ width: "250px" }}>
                              <i className="fas fa-hand-point-up"></i> Export csv
                            </button>
                          </Link> */}
                          <Link to="/transferdeals" className='m-2'>
                            <button className="btn btn-light bg-invi text-light" title="Transfer" style={{ width: "250px" }}>
                              <i className="fas fa-history"></i> Transfers Deals To Historic
                            </button>
                          </Link>
                          <Link to="/addusers">
                            <button className="btn btn-light bg-invi text-light" title="Add users" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> {t('upload.user')}
                            </button>
                          </Link>
                          <Link to="/usersitdowns">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> Users Sit Downs
                            </button>
                          </Link>
                          <Link to="/monday">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> Monday data
                            </button>
                          </Link>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                :
                <div className='container-fluid text-center' data-aos="fade-up" data-aos-duration="1000">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <Link to="/projects" className='m-2'>
                      <button className="btn btn-light bg-invi text-light" title="Add users" style={{ width: "250px" }}>
                        <i className="fas fa-circle-check"></i> Verify projects
                      </button>
                    </Link>
                    <Link to="/addzoom" className='m-2'>
                      <button className="btn btn-light bg-invi text-light" title="Add users" style={{ width: "250px" }}>
                        <i className="fas fa-video"></i> Notify Zoom Link
                      </button>
                    </Link>
                    {/* <div className='m-2'>
                      <button onClick={handlePoints} className="btn btn-light bg-invi text-light" title="Add users" style={{ width: "250px" }}>
                        Reassignate Points
                      </button>
                    </div> */}
                    <Link to="/notificationssender">
                      <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                        <i className='fa fa-bell'></i> Notifications sender
                      </button>
                    </Link>
                    {/* <div className='m-2'>
                      <button onClick={exportActiveUsers} className="btn btn-light bg-invi text-light" title="Add users" style={{ width: "250px" }}>
                        Export active users
                      </button>
                    </div> */}
                    <div className="dropdown-center mt-2">
                      <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-cog"></i> Actions
                      </button>
                      <ul className="dropdown-menu bg-invi p-0 m-0 border-0" aria-labelledby="dropdownMenuButton1">
                        <div className='gap-1 d-flex flex-column justify-content-center align-items-center'>
                          <Link to="/hotleads">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-fire"></i> Hot leads
                            </button>
                          </Link>
                          <Link to="/convertexcel">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-file-excel"></i> Convert excel
                            </button>
                          </Link>
                          <Link to="/adminrewards">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-trophy"></i> Admin Rewards
                            </button>
                          </Link>
                          <Link to="/userlist">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> Users list
                            </button>
                          </Link>
                          <Link to="/userdeals">
                            <button className="btn btn-light bg-invi text-light" title="Deals tracker" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> Deals tracker
                            </button>
                          </Link>
                          <Link to="/addtraining">
                            <button className="btn btn-light bg-invi text-light" title="Add training video" style={{ width: "250px" }}>
                              <i className="fas fa-camera"></i> Create Training Video
                            </button>
                          </Link>
                          <Link to="/addcard">
                            <button className="btn btn-light bg-invi text-light" title="Add card" style={{ width: "250px" }}>
                              <i className="fas fa-square-plus"></i> Create Card
                            </button>
                          </Link>
                          <Link to="/givecard">
                            <button className="btn btn-light bg-invi text-light" title="Give card" style={{ width: "250px" }}>
                              <i className="fas fa-gift"></i> Give Card
                            </button>
                          </Link>
                          <Link to="/addevent">
                            <button className="btn btn-light bg-invi text-light" title="Add event" style={{ width: "250px" }}>
                              <i className="fas fa-calendar-plus"></i> Create Event
                            </button>
                          </Link>
                          <Link to="/addreward">
                            <button className="btn btn-light bg-invi text-light" title="Add reward" style={{ width: "250px" }}>
                              <i className="fas fa-trophy"></i> Create Reward
                            </button>
                          </Link>
                          <Link to="/addpost">
                            <button className="btn btn-light bg-invi text-light" title="Add post" style={{ width: "250px" }}>
                              <i className="fas fa-bell"></i> Create Announcement
                            </button>
                          </Link>
                          {/* <Link to="/assigncard">
                            <button className="btn btn-light bg-invi text-light" title="Assign card closer" style={{ width: "250px" }}>
                              <i className="fas fa-hand-point-up"></i> Assign Cards
                            </button>
                          </Link>
                          <Link to="/addusersdata">
                            <button className="btn btn-light bg-invi text-light" title="Add users data canva" style={{ width: "250px" }}>
                              <i className="fas fa-door-open"></i> Add Canvasser Data
                            </button>
                          </Link> */}
                          {/* <Link to="/exportcsv">
                            <button className="btn btn-light bg-invi text-light" title="Assign card closer" style={{ width: "250px" }}>
                              <i className="fas fa-hand-point-up"></i> Export csv
                            </button>
                          </Link> */}
                          <Link to="/transferdeals" className='m-2'>
                            <button className="btn btn-light bg-invi text-light" title="Transfer" style={{ width: "250px" }}>
                              <i className="fas fa-history"></i> Transfers Deals To Historic
                            </button>
                          </Link>
                          <Link to="/addusers">
                            <button className="btn btn-light bg-invi text-light" title="Add users" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> {t('upload.user')}
                            </button>
                          </Link>
                          <Link to="/usersitdowns">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> Users Sit Downs
                            </button>
                          </Link>
                          <Link to="/monday">
                            <button className="btn btn-light bg-invi text-light" title="List users" style={{ width: "250px" }}>
                              <i className="fas fa-user"></i> Monday data
                            </button>
                          </Link>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
            }
          </div>
      }
    </>
  );
};
