import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { isMobile } from 'react-device-detect';
import { AdminStatsRepcard } from './AdminStatsRepcard';
import { AdminStatsDeals } from './AdminStatsDeals';
import { AdminStatsSitDownsGeneral } from './AdminStatsSitDownsGeneral';

export const AdminStatsTab = ({ id, office, isAdmin, isAutomatic }) => {
  return (
    <>
      {
        isAdmin
          ?
          <Tabs selectedTabClassName="bg-light text-dark rounded m-3 mb-1">
            <TabList className={isMobile ? 'text-center text-light font-speciallblue-tabmycards' : 'text-center text-light font-special-scroll-3'}>
              <Tab>Deals</Tab>
              {/* <Tab>Sit Downs</Tab> */}
              <Tab>App/DK</Tab>
            </TabList>
            <TabPanel>
              <AdminStatsDeals id={id} cancels={false} office={office} isAdmin={isAdmin} isAutomatic={isAutomatic} />
            </TabPanel>
            {/* <TabPanel>
              <AdminStatsSitDownsGeneral id={id} cancels={false} office={office} isAdmin={isAdmin} />
            </TabPanel> */}
            <TabPanel>
              <AdminStatsRepcard id={id} office={office} isAdmin={isAdmin} />
            </TabPanel>
          </Tabs>
          :
          <Tabs selectedTabClassName="bg-light text-dark rounded m-3 mb-1">
            <TabList className={isMobile ? 'text-center text-light font-speciallblue-tabmycards' : 'text-center text-light font-special-scroll-3'}>
              <Tab>Deals/Sit downs</Tab>
              <Tab>App/DK</Tab>
            </TabList>
            <TabPanel>
              <AdminStatsDeals id={id} cancels={false} office={office} isAdmin={isAdmin} isAutomatic={isAutomatic} />
            </TabPanel>
            <TabPanel>
              <AdminStatsRepcard id={id} office={office} isAdmin={isAdmin} />
            </TabPanel>
          </Tabs>
      }
    </>
  );
};
