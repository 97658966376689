import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { fetchConToken } from '../../../../helpers/fetch';
import { LoadingSpinnerWhite } from '../../../ui/LoadingSpinnerWhite';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import Select from 'react-select';
import colourStyles from '../../../../helpers/selectStyles';

export const AdminStatsRepcardOfficeSimple = ({ cancels, id, officee, isAdmin }) => {
  const navigate = useNavigate();
  // const [usersCloser, setUsersCloser] = useState([]);
  // const [usersApp, setUsersApp] = useState([]);
  const [usersPercentage, setUsersPercentage] = useState([]);
  const [totalApp, setTotalApp] = useState(0);
  const [totalDK, setTotalDK] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [dateS, setDateS] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const [office, setOffice] = useState(null);
  // const offices = [
  //   { value: 'Boca Raton', label: 'Boca Raton' },
  //   { value: 'Bradenton', label: 'Bradenton' },
  //   { value: 'Cape Coral', label: 'Cape Coral' },
  //   { value: 'Jacksonville', label: 'Jacksonville' },
  //   { value: 'Argentina', label: 'Argentina' },
  // ];
  const offices = isAdmin
    ?
    [
      { value: 'Boca Raton', label: 'Boca Raton' },
      { value: 'Bradenton', label: 'Bradenton' },
      { value: 'Cape Coral', label: 'Cape Coral' },
      { value: 'Jacksonville', label: 'Jacksonville' },
    ]
    :
    [
      { value: officee, label: officee },
    ];
  const handleOffice = async (e) => {
    setOffice(e);
    if (dateS) {
      setIsLoading(true);
      // const body = await fetchConToken('userdata/usersdata/leads/byoffice', { date: dateS, office: e.value }, 'POST');
      // setUsersCloser(body.leads);
      // const bodi = await fetchConToken('userdata/usersdata/appointments/byoffice', { date: dateS, office: e.value }, 'POST')
      // setUsersApp(bodi.leads);

      const bodu = await fetchConToken('userdata/usersdata/percentagesuccess/byoffice', { date: dateS, office: e.value }, 'POST')
      setUsersPercentage(bodu.leads);
      setTotalDK(bodu.totalDK);
      setTotalApp(bodu.totalApp);
      setPercentage(bodu.totalPercentage);

      setIsLoading(false);
    }
  };

  const goBack = () => {
    navigate('/statsdata');
  }

  const handleDateChange = async (e) => {
    setDateS(e);
    if (office) {
      setIsLoading(true);
      // const body = await fetchConToken('userdata/usersdata/leads/byoffice', { date: e, office: office.value }, 'POST');
      // setUsersCloser(body.leads);
      // const bodi = await fetchConToken('userdata/usersdata/appointments/byoffice', { date: e, office: office.value }, 'POST')
      // setUsersApp(bodi.leads);

      const bodu = await fetchConToken('userdata/usersdata/percentagesuccess/byoffice', { date: e, office: office.value }, 'POST')
      setUsersPercentage(bodu.leads);
      setTotalDK(bodu.totalDK);
      setTotalApp(bodu.totalApp);
      setPercentage(bodu.totalPercentage);

      setIsLoading(false);
    }
  };

  const [sortOrder, setSortOrder] = useState('ASC');
  const [sortName, setSortName] = useState('percentage');

  const sortApps = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (a.appointments < b.appointments) {
          return -1;
        }
        if (a.appointments > b.appointments) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (b.appointments < a.appointments) {
          return -1;
        }
        if (b.appointments > a.appointments) {
          return 1;
        }
        return 0;
      })
    }
    setUsersPercentage(usersSorted);
  };
  const sortLeads = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (a.leads < b.leads) {
          return -1;
        }
        if (a.leads > b.leads) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (b.leads < a.leads) {
          return -1;
        }
        if (b.leads > a.leads) {
          return 1;
        }
        return 0;
      })
    }
    setUsersPercentage(usersSorted);
  };
  const sortPercentage = async (sortN) => {
    setSortName(sortN);
    let usersSorted = [];
    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (a.percentage < b.percentage) {
          return -1;
        }
        if (a.percentage > b.percentage) {
          return 1;
        }
        return 0;
      })
    } else {
      setSortOrder('ASC');
      usersSorted = usersPercentage.sort((a, b) => {
        if (b.percentage < a.percentage) {
          return -1;
        }
        if (b.percentage > a.percentage) {
          return 1;
        }
        return 0;
      })
    }
    setUsersPercentage(usersSorted);
  };

  return (
    <div className={isMobile ? 'bg-primary-dark vh-110' : 'bg-primary-dark'}>
      {
        isLoading
          ?
          <LoadingSpinnerWhite />
          :
          <>
            {
              isMobile
                ?
                <div className='position-relative'>
                  <div className='d-flex justify-content-center align-items-center pt-2'>
                    <button onClick={goBack} className='btn btn-light'><i className='fa fa-chevron-left'></i></button>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center text-center text-admin-stats'>
                    <span className='font-special-total-deals m-5'>{monthNames[date.getMonth()]}</span>
                    <span className='font-special-total-deals'>TOTAL SALES</span>
                    {/* <div className='bg-primary-dark rounded-3 p-4 m-3'>
                <span className='font-special-total-deals-value'>{total}</span>
              </div> */}
                  </div>
                  <img className='logo-be-admin-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                  <img className='logo-be-admin-stats-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1704224590/Asset_2_zo1wyw.png'} style={{ objectFit: "contain" }} width={200} height={100} alt="back" />
                </div>
                :
                <div className='w-100 text-center vh-115 position-relative'>

                  <img className='logo-stats-decoration-2' src={'https://res.cloudinary.com/drholseqp/image/upload/v1705439657/Asset_1_1_qxqhqj.png'} style={{ objectFit: "contain" }} alt="back" />
                  <DateRangePicker className={"bg-light rounded-3 mb-1"} onChange={(e) => { handleDateChange(e) }} value={dateS} />
                  <div className="form-group d-flex flex-row justify-content-center align-items-center mb-3">
                    <div className="w-20">
                      <Select styles={colourStyles} options={offices} value={office} onChange={handleOffice} />
                    </div>
                  </div>
                  <div className='row pt-5 mt-3 vw-100 position-absolute'>
                    <div className='col-12 row'>
                      <div className='col-6 d-flex flex-column justify-content-center align-items-center rounded-3 mb-4'>
                        <img className='img-stats' src={'https://res.cloudinary.com/drholseqp/image/upload/v1694440370/images-logos/001_sc2glr.png'} style={{ objectFit: "contain" }} alt="back" />
                      </div>
                      <div className='col-6 d-flex flex-column justify-content-center align-items-center'>
                        {dateS && <div className='d-flex align-items-center justify-content-center w-100 p-1 rounded-3'>
                          <div className='p-2 rounded-3 d-flex flex-column align-items-center justify-content-center m-2'>
                            <span className='text-1-8 text-light text-justified'><b>{totalDK}</b> Doors Knocked</span>
                            <span className='text-1-8 text-light text-justified'><b>{totalApp}</b> Appointments</span>
                            <span className='text-1-8 text-light text-justified'><b>{percentage}</b> % App / DK</span>
                          </div>
                          <div className='bg-light p-2 rounded-3 d-flex flex-column align-items-center justify-content-center m-2'>
                            <span className='text-1-5 text-primary text-justified'>{monthNames[dateS[0].getMonth()]} - {monthNames[dateS[1].getMonth()]}</span>
                            <span className='text-1-5 text-primary text-justified'><b>{format(new Date(dateS[0]), 'yyyy-dd-MM')}</b> - <b>{format(new Date(dateS[1]), 'yyyy-dd-MM')}</b></span>
                          </div>
                        </div>}
                      </div>
                    </div>
                    {/* <div className='col ps-4 padding-adaptative d-flex justify-content-center align-items-center'>
                      <div className='w-100'>
                        {usersCloser && usersCloser.length > 0 && <table className={isMobile ? "table table-sm mt-3" : "table table-hover table-sm overflow-hidden mt-3 rounded-3"}>
                          <>
                            <thead className='bg-light'>
                              <tr className='bg-light'>
                                <th className={"text-1 text-center text-dark text-bold align-middle"}>#</th>
                                <th className={"text-1 text-center text-dark text-bold align-middle"}>Canvasser</th>
                                <th className={"text-1 text-center text-dark text-bold align-middle"}>Leads</th>
                              </tr>
                            </thead>
                            {usersCloser.length > 0 && usersCloser.map((rank, i) => {
                              return (
                                <tbody key={rank.id}>
                                  <tr className='bg-light'>
                                    <td className={"text-center text-dark fw-bold align-middle"}>{rank.position}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.name}</td>
                                    <td className={
                                      i === 0
                                        ?
                                        "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-1"
                                        :
                                        i === 1
                                          ?
                                          "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-2"
                                          :
                                          i === 2
                                            ?
                                            "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-3"
                                            :
                                            i === 3
                                              ?
                                              "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-4"
                                              :
                                              i === 4
                                                ?
                                                "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-5"
                                                :
                                                i === 5
                                                  ?
                                                  "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-6"
                                                  :
                                                  "text-1 align-middle text-center text-dark fw-bold"
                                    }>{rank.leads}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </>
                        </table>}
                      </div>
                    </div>
                    <div className='col padding-adaptative d-flex justify-content-center align-items-center'>
                      <div className='w-100'>
                        {usersApp && usersApp.length > 0 && <table className={isMobile ? "table table-sm mt-3" : "table table-hover table-sm overflow-hidden mt-3 rounded-3"}>
                          <>
                            <thead className='bg-light'>
                              <tr className='bg-light'>
                                <th className={"text-1 text-center text-dark text-bold align-middle"}>#</th>
                                <th className={"text-1 text-center text-dark text-bold align-middle"}>Canvasser</th>
                                <th className={"text-1 text-center text-dark text-bold align-middle"}>Appointments</th>
                              </tr>
                            </thead>
                            {usersApp.length > 0 && usersApp.map((rank, i) => {
                              return (
                                <tbody key={rank.id + "app"}>
                                  <tr className='bg-light'>
                                    <td className={"text-center text-dark fw-bold align-middle"}>{rank.position}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.name}</td>
                                    <td className={
                                      i === 0
                                        ?
                                        "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-1"
                                        :
                                        i === 1
                                          ?
                                          "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-2"
                                          :
                                          i === 2
                                            ?
                                            "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-3"
                                            :
                                            i === 3
                                              ?
                                              "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-4"
                                              :
                                              i === 4
                                                ?
                                                "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-5"
                                                :
                                                i === 5
                                                  ?
                                                  "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-6"
                                                  :
                                                  "text-1 align-middle text-center text-dark fw-bold"
                                    }>{rank.appointments}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </>
                        </table>}
                      </div>
                    </div> */}
                    <div className='col-12 padding-adaptative d-flex justify-content-center align-items-center'>
                      <div className='w-90 table-responsive'>
                        {usersPercentage && usersPercentage.length > 0 && <table className={isMobile ? "table table-sm mt-3" : "table table-hover table-bordered table-striped table-sm overflow-hidden mt-3 rounded-3"}>
                          <>
                            <thead className='bg-light'>
                              <tr className='bg-light'>
                                <th className={"text-1 text-center text-dark text-bold align-middle"}>#</th>
                                <th className={"text-1 text-center text-dark text-bold align-middle"}>Canvasser</th>
                                <th role='button' onClick={() => { sortApps('apps') }} className="text-center align-middle text-nowrap" scope="col">
                                  {sortName === 'apps' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Apps
                                </th>
                                <th role='button' onClick={() => { sortLeads('leads') }} className="text-center align-middle text-nowrap" scope="col">
                                  {sortName === 'leads' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} Leads
                                </th>
                                <th role='button' onClick={() => { sortPercentage('percentage') }} className="text-center align-middle text-nowrap" scope="col">
                                  {sortName === 'percentage' && <i className={sortOrder === 'DESC' ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-short-wide'}></i>} % App / Lead
                                </th>
                                {/* <th className={"text-center text-dark align-middle"}>Pitch completed</th> */}
                                {/* <th className={"text-center text-dark align-middle text-nowrap"}>Not Home 5-9</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>% Not Home 5-9 / Leads</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>Not Home 9-12</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>% Not Home 9-12 / Leads</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>Not Home 1-4</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>% Not Home 1-4 / Leads</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>Renter</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>% Renter / Leads</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>Go Back</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>% Go Back / Leads</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>Not interested</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>% Not interested / Leads</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>Deal</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>% Deal / Leads</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>Has Solar</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>% Has Solar / Leads</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>Appointment</th>
                                <th className={"text-center text-dark align-middle text-nowrap"}>% Appointment / Leads</th> */}

                                {/* <th className={"text-center text-dark align-middle"}>Pitched Follow Up</th> */}
                                {/* <th className={"text-center text-dark align-middle"}>Shade</th> */}
                                {/* <th className={"text-center text-dark align-middle text-nowrap"}>Callback</th> */}
                                {/* <th className={"text-center text-dark align-middle text-nowrap"}>% Callback / Leads</th> */}
                                {/* <th className={"text-center text-dark align-middle text-nowrap"}>Sitdown</th> */}
                                {/* <th className={"text-center text-dark align-middle text-nowrap"}>% Sitdown / Leads</th> */}
                                {/* <th className={"text-center text-dark align-middle"}>Colleague</th> */}
                                {/* <th className={"text-center text-dark align-middle"}>Signed</th> */}
                                {/* <th className={"text-center text-dark align-middle"}>Network</th> */}
                                {/* <th className={"text-center text-dark align-middle"}>Prospect</th> */}
                                {/* <th className={"text-center text-dark align-middle"}>Contacted</th> */}
                                {/* <th className={"text-center text-dark align-middle"}>Onboarding</th> */}
                                {/* <th className={"text-1 text-center text-dark text-bold align-middle"}>Apps</th> */}
                                {/* <th className={"text-1 text-center text-dark text-bold align-middle"}>Leads</th> */}
                                {/* <th className={"text-1 text-center text-dark text-bold align-middle"}>% App / Lead</th> */}
                              </tr>
                            </thead>
                            {usersPercentage.length > 0 && usersPercentage.map((rank, i) => {
                              return (
                                <tbody key={rank.id}>
                                  <tr className='bg-light'>
                                    <td className={"text-center text-dark fw-bold align-middle"}>{rank.position}</td>
                                    <td className={"text-center text-dark fw-bold text-uppercase align-middle"}>{rank.name}</td>
                                    <td className={
                                      sortName !== 'apps'
                                        ?
                                        "text-center text-dark fw-bold text-uppercase align-middle"
                                        :
                                        i === 0
                                          ?
                                          "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-1"
                                          :
                                          i === 1
                                            ?
                                            "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-2"
                                            :
                                            i === 2
                                              ?
                                              "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-3"
                                              :
                                              i === 3
                                                ?
                                                "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-4"
                                                :
                                                i === 4
                                                  ?
                                                  "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-5"
                                                  :
                                                  i === 5
                                                    ?
                                                    "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-6"
                                                    :
                                                    "text-1 align-middle text-center text-dark fw-bold"
                                    }>{rank.appointment}</td>
                                    <td className={
                                      sortName !== 'leads'
                                        ?
                                        "text-center text-dark fw-bold text-uppercase align-middle"
                                        :
                                        i === 0
                                          ?
                                          "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-1"
                                          :
                                          i === 1
                                            ?
                                            "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-2"
                                            :
                                            i === 2
                                              ?
                                              "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-3"
                                              :
                                              i === 3
                                                ?
                                                "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-4"
                                                :
                                                i === 4
                                                  ?
                                                  "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-5"
                                                  :
                                                  i === 5
                                                    ?
                                                    "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-6"
                                                    :
                                                    "text-1 align-middle text-center text-dark fw-bold"
                                    }>{rank.leads}</td>
                                    <td className={
                                      sortName !== 'percentage'
                                        ?
                                        "text-center text-dark fw-bold text-uppercase align-middle"
                                        :
                                        i === 0
                                          ?
                                          "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-1"
                                          :
                                          i === 1
                                            ?
                                            "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-2"
                                            :
                                            i === 2
                                              ?
                                              "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-3"
                                              :
                                              i === 3
                                                ?
                                                "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-4"
                                                :
                                                i === 4
                                                  ?
                                                  "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-5"
                                                  :
                                                  i === 5
                                                    ?
                                                    "text-1 align-middle text-center text-dark fw-bold bg-admins-stats-deals-6"
                                                    :
                                                    "text-1 align-middle text-center text-dark fw-bold"
                                    }>{rank.percentage}%</td>
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.pitchCompleted}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.notHome59}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageNotHome59}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.notHome912}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageNotHome912}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.notHome14}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageNotHome14}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.renter}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageRenter}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.goBack}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageGoBack}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.notInterested}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageNotInterested}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.deal}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageDeal}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.hasSolar}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageHasSolar}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.appointment}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageAppointment}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.pitchedFollowUp}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.shade}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.callback}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageCallback}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.sitdown}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.percentageSitdown}%</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.colleague}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.signed}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.network}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.prospect}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.contacted}</td> */}
                                    {/* <td className="text-center text-dark fw-bold text-uppercase align-middle">{rank.onboarding}</td> */}
                                  </tr>
                                </tbody>
                              );
                            })}
                          </>
                        </table>}
                      </div>
                    </div>
                  </div>
                </div>
            }
          </>
      }
    </div>
  );
};